/*
 * On initiation. `engine` is not attached to any project or room for any specific user.
 */

const config = {
  appId: '64216351f52a61012cc78309',
  roomId: '999990909',
  tokens: [
    {
      userId: '1',
      token: '00164216351f52a61012cc78309IgCHYNcDE9VNZyPjTWcJADk5OTk5MDkwOQEAMQEAAAAj401nIADBKKA9bzGXmBSmvgr10VhpzBUEIOJGk2RgMTy5pdYr+Q==',
    },
    {
      userId: '2',
      token: '00164216351f52a61012cc78309IgCZDxAFItVNZzLjTWcJADk5OTk5MDkwOQEAMgEAAAAy401nIADKtrfDTi3AubhN6n67SYX8cavynzMVmf0Ima4L6ywp2w==',
    }
  ],
};
export default config;
