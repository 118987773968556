import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { Input, Button, Form, Modal } from 'antd';
import { Context } from '../../context';
import { setSessionInfo } from '../../utils/index';

type IForm = { roomId: string; userId: string };

const ModalWrapper = styled(Modal)`
  .ant-modal-header {
    border-bottom: none;
    padding: 15px 32px 0px;
  }
  .ant-modal-body {
    padding: 14px 32px 24px;
  }
  .ant-modal-content {
    height: 365px;
    border-radius: 8px;
  }
  .ant-modal-title {
    font-family: PingFang SC;
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: 80px;
    text-align: left;
    height: 80px;
  }
  .ant-modal-header {
    border-radius: 6px 6px 0 0;
  }
`;

const JoinRoom: FC<{ joinRoom: () => void }> = ({ joinRoom }) => {
  const [form] = Form.useForm<IForm>();
  const { setRoomId, setUserId, userId, roomId, joinFailReason } = useContext(Context);
  const [permissionError, setPermissionError] = useState<string | null>(null);

  const requestPermissions = async () => {
    try {
      setPermissionError(null); // Clear previous errors
      await navigator.mediaDevices.getUserMedia({ video: true, audio: true });
      alert('Access to camera and microphone granted!');
    } catch (error) {
      setPermissionError('Failed to access camera or microphone.');
      console.error('Permission error:', error);
    }
  };

  const onFinish = (value: IForm) => {
    const { roomId, userId } = value;
    setUserId(userId);
    setRoomId(roomId);
    joinRoom();
    setSessionInfo({ roomId, uid: userId });
    window.history.replaceState('', '', `/?userId=${userId}&roomId=${roomId}`);
  };

  return (
    <>
      <ModalWrapper
        width={390}
        title="Sign in"
        visible={true}
        closable={false}
        footer={null}
        centered
      >
        <Form form={form} onFinish={onFinish} initialValues={{ userId, roomId }}>
          <Form.Item
            name="roomId"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  const regRes = !/^[0-9a-zA-Z_\-@.]{1,128}$/.test(value);
                  return regRes
                    ? Promise.reject(new Error('Please enter a valid Room ID.'))
                    : Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="Room ID" size="large" />
          </Form.Item>
          <Form.Item
            name="userId"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  const regRes = !/^[0-9a-zA-Z_\-@.]{1,128}$/.test(value);
                  return regRes
                    ? Promise.reject(new Error('Please enter a valid User ID.'))
                    : Promise.resolve();
                },
              },
            ]}
          >
            <Input placeholder="User ID" size="large" />
          </Form.Item>
          <div style={{ color: 'red' }}>
            {joinFailReason}
          </div>
          <div style={{ color: 'red', marginBottom: 10 }}>
            {permissionError}
          </div>
          <Button onClick={requestPermissions} type="default" block>
            Request Camera & Microphone Permissions
          </Button>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              block
              size="large"
              style={{ marginTop: 15, fontWeight: 600 }}
            >
              Join
            </Button>
          </Form.Item>
        </Form>
      </ModalWrapper>
    </>
  );
};

export default JoinRoom;